import { createApp } from 'vue'
import { createPinia } from 'pinia'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/base.less'
import App from './App.vue'
import router from './router'
import VueQrcode from 'vue-qrcode'

const app = createApp(App)
const pinia = createPinia()
app.component('vue-qrcode', VueQrcode)
app.use(pinia)
app.use(router)
app.use(ElementPlus)

app.mount('#app')
