import Axios from 'axios'
import { useUserStore } from '@/stores/user'
import { useRouter } from 'vue-router'
import { createPinia } from 'pinia'

const axios = Axios.create({
    // 实际开发中应使用环境配置
    baseURL: import.meta.env.VITE_API_URL,
    timeout: 30 * 1000,
    withCredentials: false
})
const pinia = createPinia()
const userStore = useUserStore(pinia)
const router = useRouter()
/**
 * 请求拦截器
 */
axios.interceptors.request.use(
    (config) => {
        config.headers.Authorization = sessionStorage.getItem('access_token')
        userStore.token
        console.log('userStore==>', userStore.userId)
        if (userStore.userId) {
            config.headers.userId = userStore.userId
        }
        if (userStore.token) {
            config.headers.token = userStore.token
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

/**
 * 响应拦截器
 */
axios.interceptors.response.use(
    (response) => {
        console.log(response, router)
        if (response.data.status === 401) {
            // 清除登陆数据
            userStore.logout()
            // 跳转登陆
            router.push({
                name: 'login'
            })
        }
        if (response.status === 200) {
            return response.data
        }
        return response
    },
    (error) => {
        // 响应错误
        return Promise.reject(error)
    }
)

export default axios
