import { createRouter, createWebHashHistory, useRouter } from 'vue-router'
import { isEmpty } from '@/utils/index'
import { useUserStore } from '@/stores/user'

const router = createRouter({
    history: createWebHashHistory(import.meta.env.API_URL),
    routes: [
        {
            path: '/login',
            name: 'login',
            component: ()=>import('@/views/login/Index.vue'),
            meta: {
                title: '登录',
                offLogin: true
            }
        },
        {
            path: '/',
            name: 'Index',
            component: ()=>import('@/layouts/Index.vue'),
            redirect: '/home',
            children: [
                {
                    path: '/user',
                    name: 'user',
                    component: ()=>import('@/views/user/Index.vue')
                },
                {
                    path: '/home',
                    name: 'home',
                    component: ()=>import('@/views/home/Index.vue')
                }
            ]
        }
    ]
})

router.beforeEach((to: any, from: any, next: any) => {
    console.log(12312);
    
    if (to.meta.offLogin || !isEmpty(localStorage.getItem('token'))) {
        next()
    } else {
        const userStore = useUserStore()
        const router = useRouter()
        // 清除登陆数据
        userStore.logout()
        next('login')
    }
})

export default router
