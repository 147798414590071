import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
    state: () => ({
        userId: localStorage.getItem('userId') || '',
        token: localStorage.getItem('token') || '',
        userName: localStorage.getItem('userName') || '',
        headPortrait: 'https://testcos.wmy.igame123.com/Bimg/106895/80b11914-435d-405f-8307-6f35e26e0490.png',
        isLogin: !!(localStorage.getItem('userId') && localStorage.getItem('token') && localStorage.getItem('userName'))
    }),
    actions: {
        logout() {
            this.userId = ''
            this.token = ''
            this.userName = ''
            this.isLogin = false
            localStorage.removeItem('userId')
            localStorage.removeItem('token')
            localStorage.removeItem('userName')
        },
        updateLoginStatus(userId: string, token: string, userName: string) {
            this.userId = userId
            this.token = token
            this.userName = userName
            this.isLogin = false
            localStorage.setItem('userId', userId)
            localStorage.setItem('token', token)
            localStorage.setItem('userName', userName)
        }
    }
})
